import React, { useEffect, useState, useMemo } from 'react';
import './OtherBlogs.css';

const OtherBlogs = () => {
  const blogsData = useMemo(() => [
    {
      id: 1,
      title: 'Heart Surgeon Finds Natural Energy "Fix"?',
      image: '../assets/gundry-surge.webp',
      url: 'https://www.p9v2ntrk.com/28KL6/9F3647/',
      category: 'Gundry MD'
    },
    {
      id: 2,
      title: 'Watch This Vacuum Destroying a Water Jug. Top Choice of 2024',
      image: '../assets/ezvac_60_off.webp',
      url: 'https://www.p9v2ntrk.com/28KL6/686M1C/',
      category: 'EZVac Pro'
    },
    {
      id: 3,
      title: 'Are You Having Senior Moments? Memory loss?',
      image: '../assets/senior-moments.webp',
      url: 'https://www.p9v2ntrk.com/28KL6/6XM19T/',
      category: 'The Unbreakable Brain'
    },
    {
      id: 4,
      title: 'This Light Bulb Turned into a Secret Security Camera',
      image: '../assets/lightsocketsecurity_camera.webp',
      url: 'https://www.p9v2ntrk.com/28KL6/66RQ8Q/',
      category: 'Light Socket Security Cam'
    },
    {
      id: 5,
      title: 'Wake Up Refreshed. Affordable Snoring Solution',
      image: '../assets/zquiet-anti.webp',
      url: 'https://www.p9v2ntrk.com/28KL6/B5WF5B/',
      category: 'Zquiet Anti-Snore'
    },
    {
      id: 6,
      title: 'Body Aches and Pains Relieved With Japanese Technique',
      image: '../assets/rest-nex-small.webp',
      url: 'https://www.p9v2ntrk.com/28KL6/225JFQ/',
      category: 'Rest-Nex (EST)'
    },
  ], []);

  const location = window.location;
  const [urlsWithParams, setUrlsWithParams] = useState([]);

  // INPUT ID NUMBER OF BLOG POST YOU WANT TO DISPLAY
  const blogIdsToDisplay = useMemo(() => [1, 2, 3, 4, 5, 6], []);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);

    // Filter blogs based on IDs to display
    const filteredBlogs = blogsData.filter(blog => blogIdsToDisplay.includes(blog.id));

    // Update URLs with search params for filtered blogs
    const updatedUrlsWithParams = filteredBlogs.map(blog => {
      const urlWithParams = `${blog.url}?${searchParams.toString()}`;
      return urlWithParams;
    });

    // Optionally, you can log the updated URLs to verify
    console.log('Updated URLs with Params:', updatedUrlsWithParams);

    // Set the updated URLs to state
    setUrlsWithParams(updatedUrlsWithParams);
  }, [blogsData, location.search, blogIdsToDisplay]);

  return (
    <div className="other-blogs">
      {blogsData.map((blog, index) => (
        // Check if the blog ID is in the list of IDs to display
        blogIdsToDisplay.includes(blog.id) && (
          <div key={blog.id} className="blog-item">
            <a href={urlsWithParams[index]} target="_blank" rel="noopener noreferrer">
              <img src={blog.image} alt={blog.title} />
              <p>{blog.title}</p>
              <p className='category'>{blog.category}</p>
            </a>
          </div>
        )
      ))}
    </div>
  );
};

export default OtherBlogs;
